export const isEmptyCTAStyle = (style: Record<string, string>) => {
  return Object.values(style).every((value) => value === "");
};

export const capitalizeFirstLetter = (text: string) => {
  if (typeof text !== "string") return text;

  return text
    .split(" ")
    .map((word, index) => {
      if (word === "QR") return word;
      if (word === "Essayez") return word;
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(" ");
};
