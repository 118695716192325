import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import MeasuredBy from "../molecules/MeasuredBy";

import ArrowSelectIcon from "../../icons/ArrowSelectIcon";
import PhotoCameraIcon from "../../assets/icons/PhotoCameraIcon";
import HelpIcon from "../../assets/icons/HelpIcon";

import { choiceScreenUI, font, titleStyles } from "../../configs";

import "./index.css";

interface StepDeviceSelectProps {
  step: any;
  nextPhotoStep: () => void;
  nextQuestionsStep: () => void;
}

const StepDeviceSelect: React.FC<StepDeviceSelectProps> = ({
  step,
  nextPhotoStep,
  nextQuestionsStep,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation("components/device_select");

  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const desktopStyles = !isMobile
    ? {
        marginTop: "100px",
        gap: "20px",
      }
    : undefined;

  const borderColor =
    !isActive && !isHovered ? choiceScreenUI.routeCTA.borderColor : "";

  const containerStyles = {
    backgroundColor: !isActive && !isHovered ? "#FFFFFF" : "",
    borderRadius: isMobile
      ? 0
      : !isActive && !isHovered
      ? choiceScreenUI.routeCTA.borderRadius
      : "",
    border:
      !isActive && !isHovered
        ? `${choiceScreenUI.routeCTA.borderWidth} solid ${borderColor}`
        : "",
    color: !isActive && !isHovered ? choiceScreenUI.routeCTA.fontColor : "",
  };

  const fontFamily = `${font}, sans-serif`;

  return (
    <>
      <Box className="main-container" style={desktopStyles}>
        <Box
          className="button-container"
          onClick={nextQuestionsStep}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onMouseDown={() => setIsActive(true)}
          onMouseUp={() => setIsActive(false)}
          style={containerStyles}
        >
          <Box className="parts-container">
            <Box className="title-container">
              <Box className="icon-text">
                <HelpIcon fill={titleStyles.color} />
                <Typography className="title" style={{ fontFamily }}>
                  {t("titleQuestions")}
                </Typography>
              </Box>
              <Typography className="title2" style={{ fontFamily }}>
                {t("descriptionQuestions")}
              </Typography>
            </Box>
            <ArrowSelectIcon />
          </Box>
        </Box>
        <Box
          className="button-container"
          onClick={nextPhotoStep}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onMouseDown={() => setIsActive(true)}
          onMouseUp={() => setIsActive(false)}
          style={containerStyles}
        >
          <Box className="parts-container">
            <Box className="title-container">
              <Box className="icon-text">
                <PhotoCameraIcon fill={titleStyles.color} />
                <Typography className="title" style={{ fontFamily }}>
                  {t("titleScan")}
                </Typography>
              </Box>
              <Typography className="title2" style={{ fontFamily }}>
                {t("descriptionScan")}
              </Typography>
            </Box>
            <ArrowSelectIcon />
          </Box>
        </Box>
      </Box>
      <MeasuredBy step={step} />
    </>
  );
};

export default StepDeviceSelect;
