import { brandsArray } from "../configs/configLoader";

export const handleMoveToNextInput = (
  index: number,
  unit: string,
  inputRefs: React.RefObject<HTMLInputElement>[],
  isTabClicked?: boolean
) => {
  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  if (index >= 4 && !isTabClicked) return

  const unitNextIndex =
    index === 1 ? 2 : index === 2 ? 3 : index === 3 ? 4 : -1;
  const nextIndex =
    unit === "feet" ? unitNextIndex : index === 0 ? 3 : index === 3 ? 4 : -1;

  const targetInput = nextIndex !== -1 ? inputRefs[nextIndex].current : null;

  if (targetInput && nextIndex !== -1) {
    setTimeout(() => {
      if (brandDefined?.name === "Lacoste") {
        if (nextIndex === 4) {
          targetInput.focus({ preventScroll: true });
        } else {
          targetInput.focus();
        }

        const inputs = document.querySelectorAll("input");

        inputs.forEach((input) => {
          input.addEventListener("touchstart", () => {
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 0);
          });
        });
      } else {
        targetInput.focus();
      }
      targetInput.select();
    }, 100);
  }
};

export const isPhysicalAndroidDevice = () => {
  const ua = navigator.userAgent || "";
  const platform = navigator.platform || "";

  const isAndroid =
    /Android/i.test(ua) && /(Linux|arm|x86|x64)/i.test(platform);

  const hasTouch = "ontouchstart" in window && navigator.maxTouchPoints > 0;

  const screenWidth = Math.min(window.screen.width, window.screen.height);
  const screenHeight = Math.max(window.screen.width, window.screen.height);
  const isMobileScreen = screenWidth <= 480 && screenHeight <= 800;

  const isRealDevice = !!(
    navigator.hardwareConcurrency && navigator.hardwareConcurrency <= 8
  );

  return isAndroid && hasTouch && (isMobileScreen || isRealDevice);
};
