import { FC, useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import MeasuredBy from "../molecules/MeasuredBy";

import { BASE_SIZES } from "../../constants/modal";

import {
  disabledStylesContinue,
  activeStylesContinue,
  femaleBraScreenUI,
  welcomeScreenUI,
  font,
  titleStyles,
} from "../../configs";
import { capitalizeFirstLetter } from "../../utils";
import { brandsArray } from "../../configs/configLoader";
import StyledSelector from "./components/StyledSelector";
import BaseSizeSelector from "./components/BaseSizeSelector";

import "./index.css";

interface IPropsStepBreasts {
  step: any;
  value_1: {
    current: string;
  };
  value_2: {
    current: string;
  };
  nextStep: () => void;
  skipResult: () => void;
  sizeCountry: string;
  setSizeCountry: React.Dispatch<React.SetStateAction<string>>;
  subtitleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
}

type BodyItemsType = "unfocused" | "focused";

const StepBreasts: FC<IPropsStepBreasts> = ({
  step,
  value_1,
  value_2,
  nextStep,
  skipResult,
  sizeCountry,
  setSizeCountry,
  subtitleStyles,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [open, setOpen] = useState(false);

  const { t } = useTranslation("components/breasts");

  const [selectedSize, setSelectedSize] = useState<any | undefined>();
  const [selectedCup, setSelectedCup] = useState<any | undefined>();

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const fontFamily = `${font}, sans-serif`;

  useEffect(() => {
    if (selectedSize !== undefined && selectedCup !== undefined) {
      value_1.current =
        sizeCountry === "FR"
          ? selectedSize?.size?.toString()
          : selectedSize?.fr?.toString();
      value_2.current =
        sizeCountry === "FR"
          ? selectedCup?.size?.toString()
          : selectedCup?.fr?.toString();
    }
  }, [sizeCountry, selectedSize, selectedCup, value_1, value_2]);

  const handlePaddingForBrand = (brandName: string) => {
    const smallPadding = "8px";
    const mediumPadding = "13px";
    const bigPadding = "20px";

    const smallPaddingBrands = [
      "Lacoste",
      "KENZO",
      "Sporty & Rich",
      "Le Slip Français",
      "IZAC",
      "From Future",
      "Molli",
      "Place du Jour",
      "Gualap",
      "Vogstore",
    ];
    const mediumPaddingBrands = [
      "JOTT",
      "Circle SportsWear",
      "Chlore",
      "SRP",
      "Hartford",
      "La Petite Etoile",
      "Mister K",
      "Petrone",
      "Bensimon",
      "Stella & Suzie",
    ];
    const bigPaddingBrands = [
      "Place des Tendances",
      "Rodier",
      "La Canadienne",
      "Gerard Darel",
      "Ron Dorff",
      "The Kooples",
      "Zadig & Voltaire",
    ];

    if (smallPaddingBrands.includes(brandName)) {
      return smallPadding;
    } else if (mediumPaddingBrands.includes(brandName)) {
      return mediumPadding;
    } else if (bigPaddingBrands.includes(brandName)) {
      return bigPadding;
    } else {
      return smallPadding;
    }
  };

  const getBodyItemsStyles = (type: BodyItemsType) => {
    const padding = brandDefined?.name
      ? handlePaddingForBrand(brandDefined?.name)
      : "8px";

    const sizeSelectorItem = femaleBraScreenUI.sizeSelector;

    const bodyItemsStyles = {
      padding,
      backgroundColor: sizeSelectorItem[type].backgroundColor,
      borderRadius: sizeSelectorItem[type].borderRadius,
      borderWidth: sizeSelectorItem[type].borderWidth,
      color: sizeSelectorItem[type].fontColor,
      fontSize: sizeSelectorItem[type].fontSize,
      fontWeight: sizeSelectorItem[type]
        .fontWeight as React.CSSProperties["fontWeight"],
      textTransform: sizeSelectorItem[type]
        .textTransform as React.CSSProperties["textTransform"],
      border: `${sizeSelectorItem[type].borderWidth} solid ${sizeSelectorItem[type].borderColor}`,
      height: isMobile ? "24px" : `calc(${padding} * 3)`,
      fontFamily,
    };

    return bodyItemsStyles;
  };

  const titleTextTransform =
    titleStyles.textTransform as React.CSSProperties["textTransform"];

  const titlesStyles = {
    fontWeight: welcomeScreenUI.input_fields.title.fontWeight,
    textAlign: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleTextTransform === "capitalize" ? "none" : titleTextTransform,
    color: welcomeScreenUI.input_fields.title
      .fontColor as React.CSSProperties["color"],
    fontSize: isMobile
      ? "14px"
      : (welcomeScreenUI.input_fields.title
          .fontSize as React.CSSProperties["fontSize"]),
    justifyContent: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["justifyContent"],
  };

  return (
    <div className="breasts">
      <p
        className="breasts-description"
        style={{
          ...subtitleStyles,
          textTransform:
            subtitleStyles.textTransform === "capitalize"
              ? "none"
              : (subtitleStyles.textTransform as React.CSSProperties["textTransform"]),
          marginBottom: isMobile ? "25px" : "15px",
        }}
      >
        {subtitleStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("description"))
          : t("description")}
      </p>
      <div
        className="breasts__titles"
        style={{
          display: "flex",
        }}
      >
        <p className="breasts__titles__title" style={titlesStyles}>
          {titleTextTransform === "capitalize"
            ? capitalizeFirstLetter(t("pays"))
            : t("pays")}
        </p>
        <div onClick={() => setOpen(!open)}>
          {brandDefined?.name === "SRP" ? (
            <StyledSelector
              sizeCountry={sizeCountry}
              setSizeCountry={setSizeCountry}
              open={open}
              setOpen={setOpen}
            />
          ) : (
            <BaseSizeSelector
              sizeCountry={sizeCountry}
              setSizeCountry={setSizeCountry}
              open={open}
              setOpen={setOpen}
            />
          )}
        </div>
      </div>
      <div className="breasts__titles">
        <p className="breasts__titles__title" style={titlesStyles}>
          {titleTextTransform === "capitalize"
            ? capitalizeFirstLetter(t("size"))
            : t("size")}
        </p>
      </div>
      <div className="breasts__body">
        <table className="breasts__body__table">
          <tbody>
            <tr>
              {BASE_SIZES.find(
                (value: any) => value.name === sizeCountry
              )?.band_sizes.map((row: any) => (
                <td key={row?.size}>
                  <button
                    className={`breasts__body__table__cell ${
                      selectedSize && selectedSize?.size === row?.size
                        ? "breasts__body__table__cell--selected"
                        : undefined
                    }`}
                    onClick={() => setSelectedSize(row)}
                    style={Object.assign({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width:
                        row?.size?.toString()?.length > 4
                          ? "auto"
                          : isMobile
                          ? "24px"
                          : `calc(${
                              getBodyItemsStyles(
                                selectedSize?.size === row?.size
                                  ? "focused"
                                  : "unfocused"
                              ).padding
                            } * 3)`,
                      ...(selectedSize?.size === row?.size
                        ? getBodyItemsStyles("focused")
                        : getBodyItemsStyles("unfocused")),
                    })}
                  >
                    {row?.size}
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="breasts__titles">
          <p
            className="breasts__titles__title"
            style={{
              ...titlesStyles,
              position: "relative",
              top: isMobile ? 0 : "10px",
            }}
          >
            {titleTextTransform === "capitalize"
              ? capitalizeFirstLetter(t("cup"))
              : t("cup")}
          </p>
        </div>
        <table className="breasts__body__table">
          <tbody>
            <tr>
              {BASE_SIZES.find(
                (value: any) => value.name === sizeCountry
              )?.cup_sizes.map((row) => (
                <td key={row?.size} className="breasts__body__table__test">
                  <button
                    className={`breasts__body__table__cell ${
                      selectedCup && selectedCup?.size === row?.size
                        ? "breasts__body__table__cell--selected"
                        : undefined
                    }`}
                    onClick={() => setSelectedCup(row)}
                    style={Object.assign({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width:
                        row?.size?.toString()?.length > 4
                          ? "auto"
                          : isMobile
                          ? "24px"
                          : `calc(${
                              getBodyItemsStyles(
                                selectedCup?.size === row?.size
                                  ? "focused"
                                  : "unfocused"
                              ).padding
                            } * 3)`,
                      ...(selectedCup?.size === row?.size
                        ? getBodyItemsStyles("focused")
                        : getBodyItemsStyles("unfocused")),
                    })}
                  >
                    {row?.size}
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <button
        type="button"
        disabled={selectedSize === undefined || selectedCup === undefined}
        className="continue-button breasts-continue"
        onClick={() => nextStep()}
        style={{
          marginTop: isMobile ? "60px" : "30px",
          position: "relative",
          marginBottom: isMobile ? "20px" : "0",
          ...(selectedSize === undefined || selectedCup === undefined
            ? disabledStylesContinue
            : activeStylesContinue),
          textTransform:
            activeStylesContinue.textTransform === "capitalize"
              ? "none"
              : (activeStylesContinue.textTransform as React.CSSProperties["textTransform"]),
        }}
      >
        {activeStylesContinue.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("continue"))
          : t("continue")}
      </button>
      <span
        className="breasts-skip"
        onClick={() => skipResult()}
        style={{
          color: femaleBraScreenUI.skip.fontColor,
          fontWeight: femaleBraScreenUI.skip.fontWeight,
          fontSize: femaleBraScreenUI.skip.fontSize,
          textDecoration: femaleBraScreenUI.skip.fontStyle,
        }}
      >
        {t("skip")}
      </span>
      <MeasuredBy step={step} />
    </div>
  );
};

export default StepBreasts;
