import { brandsArray } from "../configs/configLoader";
import { API_ENDPOINT, GENDERS } from "../constants/modal";
import {
  questionMalePushAnswersSchema,
  questionFemalePushAnswersSchema,
} from "../validation/pushAnswers";

type MeasureType = "question" | "scan";

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const isOnline = () => {
  return navigator.onLine;
};

export const newUser = async (domain: string, retries = 3, delay = 50) => {
  let attempt = 0;

  if (!isOnline()) {
    attempt++;
    if (attempt > retries) {
      console.log("No internet connection after retries");
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
  }

  if (domain) {
    while (attempt <= retries) {
      try {
        localStorage.setItem("domain", domain);

        const response = await fetch(`${API_ENDPOINT}new-user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            retailer: domain,
            device_id: "abcdefghiju",
          }),
        });

        if (!response.ok) {
          // throw new Error(`Server error: ${response.status}`);
          return false;
        }

        const res = await response.json();

        const uid = res["user_id"];
        if (uid) {
          localStorage.setItem("uid", uid);
          return uid;
        } else {
          throw new Error("No user_id in response");
        }
      } catch (error) {
        attempt++;
        if (attempt > retries) {
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  } else {
    localStorage.setItem("domain", brandsArray?.[0]?.domains?.[0] || "");
  }
};

export const recommend = async (
  pid: string | null,
  retries = 3,
  delay = 50
): Promise<boolean | string | null> => {
  let attempt = 0;

  if (pid) {
    while (attempt <= retries) {
      try {
        if (!isOnline()) {
          attempt++;
          if (attempt > retries) {
            console.log("No internet connection after retries");
            return false;
          }
          await new Promise((resolve) => setTimeout(resolve, delay));
          continue;
        }

        const mid = localStorage.getItem("mid") || null;
        if (mid && uuidRegex.test(mid)) {
          const response = await fetch(
            `${API_ENDPOINT}recommend?version=1.0.4`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                measure_public_id: mid,
                product_reference: pid,
              }),
            }
          );

          if (!response.ok) {
            console.log(`Server error: ${response.status}`);
            return false;
          }

          const res = await response.json();

          if (res?.recommendation) {
            return res.recommendation;
          } else {
            console.log("No recommendation available");
            return false;
          }
        } else {
          // console.log("Invalid mid or pid");
          return false;
        }
      } catch (error: any) {
        attempt++;
        console.error(`Attempt ${attempt} failed: ${error.message}`);
        if (attempt > retries) {
          return false;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  }

  return false;
};

export const newMeasure = async (
  measure_type: MeasureType,
  user_id?: string,
  retailer_customer_tracking_id?: string | null,
  retries = 3,
  delay = 50
) => {
  let attempt = 0;

  const uid = localStorage.getItem("uid") || user_id || null;

  if (!uid || !uuidRegex.test(uid)) {
    console.log("Invalid or missing user ID");
    return false;
  }

  if (uid && measure_type) {
    while (attempt <= retries) {
      try {
        const response = await fetch(`${API_ENDPOINT}new-measure`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: uid,
            measure_type: measure_type,
            retailer_customer_tracking_id,
          }),
        });

        if (!response.ok) {
          throw new Error(`Server error: ${response.status}`);
        }

        const res = await response.json();

        const mid = res["measure_id"];
        if (mid) {
          localStorage.setItem("mid", mid);
          window.parent.postMessage({ data: "mid", mid }, "*");
          return mid;
        } else {
          throw new Error("No measure_id in response");
        }
      } catch (error: any) {
        attempt++;

        if (attempt > retries) {
          return false;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  }

  return false;
};

export const scanCheckMeasurements = async () => {
  const mid = localStorage.getItem("mid") || null;
  if (!mid) {
    return false;
  }

  try {
    const response = await fetch(`${API_ENDPOINT}scan-check-measurements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        measure_id: mid,
      }),
    });

    if (!response.ok) {
      throw new Error(`Server error: ${response.status}`);
    }

    const res = await response.json();

    return res["completed"] ?? false;
  } catch (error: any) {
    if (
      error.message.includes("Failed to fetch") ||
      error.message.includes("No internet connection")
    ) {
      throw error;
    }

    return false;
  }
};

export const questionPushAnswers = async (answers: object) => {
  const mid = localStorage.getItem("mid") || null;
  if (mid && uuidRegex.test(mid)) {
    const body = {
      measure_id: mid,
      answers: answers,
    };

    try {
      const gender = (answers as any).gender;
      if (gender === GENDERS.M) {
        questionMalePushAnswersSchema.parse(body);
      } else if (gender === GENDERS.F) {
        questionFemalePushAnswersSchema.parse(body);
      } else {
        throw new Error("Invalid gender value");
      }

      const success = await fetch(`${API_ENDPOINT}question-push-trigger`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => res)
        .catch((e) => {
          console.log("Error: questionPushAnswersAndTrigger - ", e);
          return false;
        });

      return !!success;
    } catch (error) {
      console.error("Validation Error:", error);
      return false;
    }
  } else {
    return false;
  }
};

export const checkStocks = async (domain: string, pid: string | null) => {
  if (pid) {
    const success = await fetch(`${API_ENDPOINT}stocks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        retailer_domain: domain,
        product_reference: pid,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data) {
          return res?.data;
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("Error: recommend - ", e);
        return null;
      });
    return success;
  } else {
    return false;
  }
};

export const findSimilarProducts = async (
  domain: string,
  pid: string,
  variant_id: string | null
) => {
  const success = await fetch(`${API_ENDPOINT}similar-products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      retailer: domain,
      product_reference: pid,
      variant_reference: variant_id,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    })
    .catch((e) => {
      console.log("Error: recommend - ", e);
      return null;
    });
  return success;
};
