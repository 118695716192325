import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { font, welcomeScreenUI } from "../../../../configs";
import { GENDERS } from "../../../../constants/modal";
import { capitalizeFirstLetter } from "../../../../utils";
import { brandsArray } from "../../../../configs/configLoader";

import "./index.css";

interface GenderBlockProps {
  selectedGender: string;
  handleGenderSelect: (newUnit: string) => void;
  nextStep: () => void;
}

type GenderButtonType = "focused" | "unfocused";

const GenderBlock: React.FC<GenderBlockProps> = ({
  selectedGender,
  handleGenderSelect,
  nextStep,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { t } = useTranslation("components/intro");

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const getButtonsStyles = (type: GenderButtonType) => {
    const defaultButtonsStyles = {
      backgroundColor: welcomeScreenUI.genderCTA[type].backgroundColor,
      borderRadius: welcomeScreenUI.genderCTA[type].borderRadius,
      borderColor: welcomeScreenUI.genderCTA[type].borderColor,
      borderWidth: welcomeScreenUI.genderCTA[type].borderWidth,
      fontSize: welcomeScreenUI.genderCTA[type].fontSize,
      fontWeight: welcomeScreenUI.genderCTA[type].fontWeight,
      textTransform:
        brandDefined?.name === "Lacoste" && isMobile
          ? "uppercase"
          : (welcomeScreenUI.genderCTA[type]
              .textTransform as React.CSSProperties["textTransform"]),
      color: welcomeScreenUI.genderCTA[type].fontColor,
      fontFamily: `${font}, sans-serif`,
    };

    return defaultButtonsStyles;
  };

  const femaleButtonStyles =
    selectedGender === GENDERS.F
      ? getButtonsStyles("focused")
      : getButtonsStyles("unfocused");

  const maleButtonStyles =
    selectedGender === GENDERS.M
      ? getButtonsStyles("focused")
      : getButtonsStyles("unfocused");

  return (
    <div className="gender-buttons">
      <button
        type="button"
        className={selectedGender === GENDERS.F ? "selected" : ""}
        style={{
          ...femaleButtonStyles,
          textTransform:
            femaleButtonStyles.textTransform === "capitalize"
              ? "none"
              : femaleButtonStyles.textTransform,
        }}
        onClick={() => {
          handleGenderSelect(GENDERS.F);
          if (isMobile) nextStep();
        }}
      >
        {femaleButtonStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("gender.female"))
          : t("gender.female")}
      </button>
      <button
        type="button"
        className={selectedGender === GENDERS.M ? "selected" : ""}
        style={{
          ...maleButtonStyles,
          textTransform:
            maleButtonStyles.textTransform === "capitalize"
              ? "none"
              : maleButtonStyles.textTransform,
        }}
        onClick={() => {
          handleGenderSelect(GENDERS.M);
          if (isMobile) nextStep();
        }}
      >
        {maleButtonStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("gender.male"))
          : t("gender.male")}
      </button>
    </div>
  );
};

export default GenderBlock;
