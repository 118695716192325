import React, { FC, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import MeasuredBy from "../molecules/MeasuredBy";
import { activeStylesContinue, resultScreenUI, uxGender } from "../../configs";

import {
  CheckCircle as CheckCircleIcon,
  Check as CheckIcon,
} from "@mui/icons-material";

import H_twh from "../../assets/result/H_twh.svg";
import H_ctw from "../../assets/result/H_ctw.svg";
import H_ctwh from "../../assets/result/H_ctwh.svg";
import H_h from "../../assets/result/H_h.svg";
import H_h_unisex from "../../assets/result/H_h_unisex.svg";
import H_c from "../../assets/result/H_c.svg";

import F_c from "../../assets/result/F_c.svg";
import F_cw from "../../assets/result/F_cw.svg";
import F_ctwh from "../../assets/result/F_ctwh.svg";
import F_wh from "../../assets/result/F_wh.svg";
import F_h_unisex from "../../assets/result/F_h_unisex.svg";

import { removeLocalStore, setLocalStore } from "../../store/localStoreUtils";
import { useUserContext } from "../../store/userContext";
import { ReducedResultType, SizeDataType } from "../../types/result";
import { GENDERS } from "../../constants/modal";
import SizeSelector from "./components/SizeSelector";
import { capitalizeFirstLetter } from "../../utils";
import UnavailableGallery from "./components/UnavailableGallery";
import { findSimilarProducts } from "../../api/endpoints";
import { brandsArray } from "../../configs/configLoader";

import "./index.css";

type SelectorSizeType = "unfocused" | "focused" | "unavailable";

interface IPropsResult {
  step: any;
  reducedResult: ReducedResultType;
  selectedGender: string;
  productStockData: any;
  similarProducts: any;
  isSizeUnavailable: boolean;
  selectedSize: SizeDataType | null;
  setSelectedSize: React.Dispatch<React.SetStateAction<SizeDataType | null>>;
  setSimilarProducts: React.Dispatch<any>;
  restart: () => void;
}

const Result: FC<IPropsResult> = ({
  step,
  reducedResult,
  selectedGender,
  productStockData,
  similarProducts,
  isSizeUnavailable,
  selectedSize,
  setSelectedSize,
  setSimilarProducts,
  restart,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isSmallMobile = useMediaQuery({ maxWidth: 550 });
  const userContext = useUserContext() || undefined;
  const user = userContext?.user;

  const { t } = useTranslation("components/results/result");

  const fit_feedbacks = [t("size.size1"), t("size.size2"), t("size.size3")];

  const [selectedVariant, setSelectedVariant] = useState<string>("1");
  const [selectedSizeImage, setSelectedSizeImage] = useState<string>("");
  const [feedbacks, setFeedbacks] = useState<any>({});

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  useEffect(() => {
    if (reducedResult) {
      const entries = Object.entries(reducedResult);
      if (entries.length > 1) {
        setSelectedVariant(entries[1][0]);
      }
    }
  }, [reducedResult]);

  const selectSizePost = (size: string) => {
    window.parent.postMessage(
      {
        action: "selectSize",
        size,
      },
      "*"
    );
  };

  useEffect(() => {
    let activeRequestId = null;

    const handleFindSimilarProducts = async (variant_id: any) => {
      const urlParameters = new URLSearchParams(window.location.search);
      const product_id = urlParameters.get("product_id");
      const domain = urlParameters.get("domain");

      const noVariantIdBrands = [
        "Place des Tendances",
        "Rodier",
        "La Canadienne",
      ];
      const variantIsUnavailable = brandDefined?.name
        ? noVariantIdBrands?.includes(brandDefined?.name)
        : false;

      const variantId = variantIsUnavailable ? null : variant_id;

      if (domain && product_id && productStockData?.length) {
        const currentRequestId = Symbol("requestId");
        activeRequestId = currentRequestId;

        setSimilarProducts([]);

        try {
          const similarProducts = await findSimilarProducts(
            domain,
            product_id,
            variantId
          );

          if (activeRequestId === currentRequestId) {
            setSimilarProducts(similarProducts);
          }
        } catch (error) {
          console.error("error:", error);
        }
      }
    };

    if (selectedVariant && reducedResult) {
      const size = reducedResult[selectedVariant];
      setSelectedSize(size);
      selectSizePost(size?.variant_id);

      if (isSizeUnavailable) {
        handleFindSimilarProducts(size?.variant_id);
      }
    }

    return () => {
      activeRequestId = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedVariant,
    reducedResult,
    isSizeUnavailable,
    productStockData?.length,
  ]);

  const findByLabelRank = (rank: number) => {
    if (reducedResult) {
      const foundItem = Object.values(reducedResult).find(
        (item) => item.label_rank === rank
      );
      return foundItem?.label ? foundItem : null;
    }
    return null;
  };

  const itemWithLabelRank0 = findByLabelRank(0);
  const itemWithLabelRank2 = findByLabelRank(2);

  const getActualDescription = () => {
    if (selectedVariant) {
      switch (selectedVariant) {
        case "0":
          return selectedSize?.possible === 0
            ? "description.size_down.not_possible"
            : "description.size_down.ideal";
        case "1":
          return "description.normal.ideal";
        case "2":
          return selectedSize?.possible === 0
            ? "description.size_up.not_possible"
            : "description.size_up.ideal";
        default:
          return "description.normal.ideal";
      }
    } else {
      return "description.normal.ideal";
    }
  };

  useEffect(() => {
    const focused = selectedVariant ? parseInt(selectedVariant, 10) : null;
    if (reducedResult && focused !== null) {
      const focusedFeeback = reducedResult[focused].fit_indicators;
      const result = focusedFeeback.reduce((acc: any, { limb, value }: any) => {
        acc[limb] = value - 1;
        return acc;
      }, {});
      setFeedbacks(result);
    }
  }, [reducedResult, selectedVariant]);

  useEffect(() => {
    if (feedbacks) {
      if (selectedGender === GENDERS.M) {
        // result 1 - MALE
        if (
          feedbacks.hip > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.chest === undefined
        ) {
          setSelectedSizeImage(H_twh);
        }

        // result 2 - MALE
        if (
          feedbacks.chest > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.hip === undefined
        ) {
          setSelectedSizeImage(H_ctw);
        }

        // result 3 - MALE
        if (
          feedbacks.chest > 0 &&
          feedbacks.waist === undefined &&
          feedbacks.hip === undefined
        ) {
          setSelectedSizeImage(H_c);
        }
      }

      if (selectedGender === GENDERS.F) {
        // result 1 - FEMALE
        if (
          feedbacks.chest > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.hip === undefined
        ) {
          setSelectedSizeImage(F_cw);
        }

        // result 2 - FEMALE
        if (
          feedbacks.hip > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.chest === undefined
        ) {
          setSelectedSizeImage(F_wh);
        }

        // result 3 - FEMALE
        if (
          feedbacks.chest > 0 &&
          feedbacks.waist === undefined &&
          feedbacks.hip === undefined
        ) {
          setSelectedSizeImage(F_c);
        }
      }

      // result 3 - MALE
      if (
        feedbacks.waist > 0 &&
        feedbacks.hip === undefined &&
        feedbacks.chest === undefined
      ) {
        setSelectedSizeImage(H_h);
      }

      // unisex result
      if (
        feedbacks.hip > 0 &&
        feedbacks.waist === undefined &&
        feedbacks.chest === undefined
      ) {
        setSelectedSizeImage(
          selectedGender === GENDERS.M ? H_h_unisex : F_h_unisex
        );
      }

      // result 3 - ALL
      if (feedbacks.chest > 0 && feedbacks.waist > 0 && feedbacks.hip > 0) {
        setSelectedSizeImage(selectedGender === GENDERS.M ? H_ctwh : F_ctwh);
      }
    } else {
      // setSelectedSizeImage(selectedGender === GENDERS.F ? F_cw : H_h);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacks, selectedGender]);

  const getSelectorSizeStyles = (type: SelectorSizeType, variant: string) => {
    const sizeStyles = resultScreenUI.sizeSelector[type]!;

    const cornerBorderWidth =
      variant === "1"
        ? `calc(${sizeStyles.borderWidth} - 1px)`
        : sizeStyles.borderWidth;

    const selectorSizeStyles = {
      backgroundColor:
        sizeStyles.backgroundColor as React.CSSProperties["backgroundColor"],
      fontWeight: sizeStyles.fontWeight as React.CSSProperties["fontWeight"],
      fontSize: sizeStyles.fontSize as React.CSSProperties["fontSize"],
      textTransform:
        sizeStyles.textTransform as React.CSSProperties["textTransform"],
      color: sizeStyles.fontColor as React.CSSProperties["color"],
      borderTopLeftRadius:
        variant === "0"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
      borderBottomLeftRadius:
        variant === "0"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
      borderTopRightRadius:
        variant === "2"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
      borderBottomRightRadius:
        variant === "2"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
      borderWidthStyle: sizeStyles.borderWidth,
      borderTop: `${sizeStyles.borderWidth} solid ${sizeStyles.borderColor}`,
      borderBottom: `${sizeStyles.borderWidth} solid ${sizeStyles.borderColor}`,
      borderLeft: `${cornerBorderWidth} solid ${
        selectedVariant === "1" && variant === "2"
          ? resultScreenUI.sizeSelector["focused"]?.borderColor
          : sizeStyles.borderColor
      }`,
      borderRight: `${cornerBorderWidth} solid ${
        selectedVariant === "1" && variant === "0"
          ? resultScreenUI.sizeSelector["focused"]?.borderColor
          : sizeStyles.borderColor
      }`,
    };

    return selectorSizeStyles;
  };

  const firstSelectorStyles =
    selectedVariant === "0"
      ? getSelectorSizeStyles(
          selectedSize?.possible === 0 && !isSizeUnavailable
            ? "unavailable"
            : "focused",
          "0"
        )
      : getSelectorSizeStyles("unfocused", "0");

  const secondSelectorStyles =
    selectedVariant === "1"
      ? getSelectorSizeStyles("focused", "1")
      : getSelectorSizeStyles("unfocused", "1");

  const thirdSelectorStyles =
    selectedVariant === "2"
      ? getSelectorSizeStyles(
          selectedSize?.possible === 0 && !isSizeUnavailable
            ? "unavailable"
            : "focused",
          "2"
        )
      : getSelectorSizeStyles("unfocused", "2");

  const skipTextTransform = resultScreenUI.restartCTA
    .textTransform as React.CSSProperties["textTransform"];

  const sendMid = () => {
    window.parent.postMessage(
      { data: "mid", mid: localStorage.getItem("mid") },
      "*"
    );
  };

  const clearMid = () => {
    window.parent.postMessage({ action: "clearMid" }, "*");
  };

  const addToCart = () => {
    window.parent.postMessage(
      {
        action: "addToCart",
        variantId: selectedSize?.variant_id,
      },
      "*"
    );
  };

  const closeIframe = () =>
    window.parent.postMessage({ action: "closeIframe" }, "*");

  return (
    <div className="result">
      <div className="size">
        <h1
          style={{
            color: isSizeUnavailable
              ? (resultScreenUI.unavailable_size_text
                  .fontColor as React.CSSProperties["color"])
              : (resultScreenUI.recommendedSize
                  .fontColor as React.CSSProperties["color"]),
            fontSize:
              brandDefined?.name !== "Lacoste" && isMobile
                ? "40px"
                : (resultScreenUI.recommendedSize
                    .fontSize as React.CSSProperties["fontSize"]),
            fontWeight: resultScreenUI.recommendedSize
              .fontWeight as React.CSSProperties["fontWeight"],
            textAlign: resultScreenUI.recommendedSize
              .textAlign as React.CSSProperties["textAlign"],
            margin: "20px 0 ",
          }}
        >
          {selectedSize?.label || ""}
        </h1>
        <div className="size-name">
          {selectedVariant === "1" && (
            <>
              <h2
                style={{
                  color: resultScreenUI.subtitles
                    .fontColor as React.CSSProperties["color"],
                  fontSize: resultScreenUI.subtitles
                    .fontSize as React.CSSProperties["fontSize"],
                  fontWeight: resultScreenUI.subtitles
                    .fontWeight as React.CSSProperties["fontWeight"],
                  textAlign: resultScreenUI.subtitles
                    .textAlign as React.CSSProperties["textAlign"],
                }}
              >
                {t("size.title")}
              </h2>
              <CheckCircleIcon
                style={{
                  color: resultScreenUI.subtitles
                    .fontColor as React.CSSProperties["color"],
                }}
              />
            </>
          )}
        </div>
        <div className="sizes-menu">
          <div
            className={`menu-item ${selectedVariant === "0" && "active"}`}
            onClick={() => {
              if (!!itemWithLabelRank0) setSelectedVariant("0");
            }}
            style={{
              ...firstSelectorStyles,
              cursor: !itemWithLabelRank0 ? "default" : "pointer",
              textTransform:
                firstSelectorStyles?.textTransform === "capitalize"
                  ? "none"
                  : firstSelectorStyles?.textTransform,
            }}
          >
            <p>
              {!itemWithLabelRank0
                ? ""
                : firstSelectorStyles?.textTransform === "capitalize"
                ? capitalizeFirstLetter(fit_feedbacks[0])
                : itemWithLabelRank0?.variant_id
                ? fit_feedbacks[0]
                : ""}
            </p>
          </div>
          <div
            className={`menu-item ${selectedVariant === "1" && "active"}`}
            onClick={() => setSelectedVariant("1")}
            style={{
              ...secondSelectorStyles,
              cursor: "pointer",
              textTransform:
                secondSelectorStyles?.textTransform === "capitalize"
                  ? "none"
                  : secondSelectorStyles?.textTransform,
            }}
          >
            <p>
              {secondSelectorStyles?.textTransform === "capitalize"
                ? capitalizeFirstLetter(fit_feedbacks[1])
                : fit_feedbacks[1]}
            </p>
          </div>
          <div
            className={`menu-item ${selectedVariant === "2" && "active"}`}
            onClick={() => {
              if (!!itemWithLabelRank2) setSelectedVariant("2");
            }}
            style={{
              ...thirdSelectorStyles,
              cursor: !itemWithLabelRank2 ? "default" : "pointer",
              textTransform:
                thirdSelectorStyles?.textTransform === "capitalize"
                  ? "none"
                  : thirdSelectorStyles?.textTransform,
            }}
          >
            <p>
              {!itemWithLabelRank2
                ? ""
                : thirdSelectorStyles?.textTransform === "capitalize"
                ? capitalizeFirstLetter(fit_feedbacks[2])
                : itemWithLabelRank2?.variant_id
                ? fit_feedbacks[2]
                : ""}
            </p>
          </div>
        </div>
      </div>
      {resultScreenUI.generateResult ? (
        <div className="result-body">
          <div className="result">
            <SizeSelector
              selectedSizeImage={selectedSizeImage}
              feedbacks={feedbacks}
            />
          </div>
          <div className="text">
            {!isSmallMobile && (
              <div className="result-title">
                <CheckIcon />
                <p>
                  {selectedVariant
                    ? fit_feedbacks[parseInt(selectedVariant, 10)]
                    : fit_feedbacks[1]}
                </p>
              </div>
            )}
            <p
              className="result-description"
              dangerouslySetInnerHTML={{
                __html: t(getActualDescription()).replace(
                  "[S]",
                  `<b>${selectedSize?.label || ""}</b>`
                ),
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="result-body-text"
          style={{
            borderBottom:
              isSizeUnavailable && similarProducts?.length
                ? "1px solid #E9E9E9"
                : "none",
            paddingBottom:
              isSizeUnavailable && similarProducts?.length ? "25px" : 0,
            justifyContent: resultScreenUI.Description
              .textAlign as React.CSSProperties["justifyContent"],
          }}
        >
          {isSizeUnavailable ? (
            <p
              style={{
                color: resultScreenUI.Description
                  .fontColor as React.CSSProperties["color"],
                fontSize: resultScreenUI.Description
                  .fontSize as React.CSSProperties["fontSize"],
                fontWeight: resultScreenUI.Description
                  .fontWeight as React.CSSProperties["fontWeight"],
                textAlign: resultScreenUI.Description
                  .textAlign as React.CSSProperties["textAlign"],
                display: "flex",
                alignItems: "center",
                lineHeight: "20px",
              }}
            >
              {t("unavailable.description")}
            </p>
          ) : (
            <div
              style={{
                color: resultScreenUI.Description
                  .fontColor as React.CSSProperties["color"],
                fontSize: resultScreenUI.Description
                  .fontSize as React.CSSProperties["fontSize"],
                fontWeight: resultScreenUI.Description
                  .fontWeight as React.CSSProperties["fontWeight"],
                textAlign: resultScreenUI.Description
                  .textAlign as React.CSSProperties["textAlign"],
                lineHeight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t(getActualDescription()).replace(
                    "[S]",
                    selectedSize?.label.replace("-", "&#8209;") || ""
                  ),
                }}
              />
            </div>
          )}
        </div>
      )}
      {isSizeUnavailable && similarProducts?.length ? (
        <UnavailableGallery similarProducts={similarProducts} />
      ) : null}
      {!isSizeUnavailable && selectedSize?.variant_id && (
        <button
          type="button"
          className="continue-button step-bottom-result"
          onClick={() => {
            if (selectedSize?.variant_id) {
              addToCart();
              sendMid();
              closeIframe();
            }
          }}
          style={{
            ...activeStylesContinue,
            textTransform:
              activeStylesContinue.textTransform === "capitalize"
                ? "none"
                : activeStylesContinue.textTransform,
          }}
        >
          {activeStylesContinue.textTransform === "capitalize"
            ? capitalizeFirstLetter(
                t("button").replace("[S]", selectedSize?.label || "")
              )
            : t("button").replace("[S]", selectedSize?.label || "")}
        </button>
      )}
      <span
        className="skip"
        onClick={() => {
          clearMid();
          restart();

          removeLocalStore("mid");
          removeLocalStore("user");
          removeLocalStore("productSize");
          if (!uxGender) removeLocalStore("gender");

          const urlParams = new URLSearchParams(window.location.search);
          removeLocalStore(`${urlParams.get("pid")}`);

          if (localStorage.getItem("user")) {
            setLocalStore("user", JSON.stringify(user));
          }
        }}
        style={{
          backgroundColor:
            brandDefined?.name === "SRP"
              ? "transparent"
              : (resultScreenUI.restartCTA
                  .backgroundColor as React.CSSProperties["backgroundColor"]),
          fontWeight: resultScreenUI.restartCTA
            .fontWeight as React.CSSProperties["fontWeight"],
          fontSize: resultScreenUI.restartCTA
            .fontSize as React.CSSProperties["fontSize"],
          color: resultScreenUI.restartCTA
            .fontColor as React.CSSProperties["color"],
          textTransform:
            skipTextTransform === "capitalize" ? "none" : skipTextTransform,
          borderRadius: resultScreenUI.restartCTA
            .borderRadius as React.CSSProperties["borderRadius"],
          border: resultScreenUI.restartCTA.borderWidth
            ? `${resultScreenUI.restartCTA.borderWidth} solid ${resultScreenUI.restartCTA.borderColor}`
            : "none",
          textDecoration: resultScreenUI.restartCTA
            .textDecoration as React.CSSProperties["borderRadius"],
          height: resultScreenUI.restartCTA.borderWidth ? "50px" : "0",
          alignItems: "center",
        }}
      >
        {skipTextTransform === "capitalize"
          ? capitalizeFirstLetter(t("skip"))
          : t("skip")}
      </span>
      <MeasuredBy step={step} />
    </div>
  );
};

export default Result;
