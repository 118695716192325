import { FC, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Lottie from "lottie-react";

import { morphoUIStyles, font } from "../../configs";
import { capitalizeFirstLetter } from "../../utils";
import { GENDERS } from "../../constants/modal";
import { brandsArray } from "../../configs/configLoader";

import "./index.css";

interface IPropsBellyChoice {
  image: string;
  onClick: () => void;
  text: string;
  isSelected?: boolean;
  type?: string;
  gender?: string;
}

type BellyChoiceType = "unfocused" | "focused";

const BellyChoice: FC<IPropsBellyChoice> = ({
  image,
  onClick,
  text,
  isSelected,
  type,
  gender,
}) => {
  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang")?.split("-")[0].toLowerCase();
  const domain = urlParams.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const torsoClass = loading
    ? "torso_choice__image_loading"
    : "torso_choice__image";

  const cuissesClass = loading
    ? "cuisses_choice__image_loading"
    : "cuisses_choice__image";

  const getSelectorStyles = (selectorType: BellyChoiceType) => {
    const ctaStyles = morphoUIStyles.morphoCTA[selectorType]!;

    const selectorSizeStyles = {
      backgroundColor:
        ctaStyles.backgroundColor as React.CSSProperties["backgroundColor"],
      borderRadius:
        ctaStyles.borderRadius as React.CSSProperties["borderRadius"],
      border: `${
        ctaStyles.borderWidth as React.CSSProperties["borderWidth"]
      } solid ${ctaStyles.borderColor as React.CSSProperties["borderColor"]}`,
      fontWeight: ctaStyles.fontWeight as React.CSSProperties["fontWeight"],
      color: ctaStyles.fontColor as React.CSSProperties["color"],
      fontSize:
        (isMobile &&
          (lang === "de" || lang === "en") &&
          type === "torso" &&
          gender === GENDERS.F) ||
        (isMobile && brandDefined?.name === "The Kooples")
          ? `calc(${ctaStyles.fontSize} - 2px)`
          : (ctaStyles.fontSize as React.CSSProperties["fontSize"]),
      textAlign: ctaStyles.textAlign as React.CSSProperties["textAlign"],
      textTransform:
        ctaStyles.textTransform as React.CSSProperties["textTransform"],
      borderWidthStyle: ctaStyles.borderWidth,
    };

    return selectorSizeStyles;
  };

  const selectorStyles = isSelected
    ? getSelectorStyles("focused")
    : getSelectorStyles("unfocused");

  const isPetroneTorso =
    type === "torso" &&
    gender === GENDERS.F &&
    brandDefined?.name === "Petrone";

  return (
    <button
      className={isSelected ? "belly_choice_selected" : "belly_choice"}
      onClick={onClick}
      style={{
        ...selectorStyles,
        fontFamily: `${font}, sans-serif`,
        textTransform:
          selectorStyles?.textTransform === "capitalize"
            ? "none"
            : selectorStyles?.textTransform,
        paddingLeft:
          isMobile && type === "belly" && gender === GENDERS.F ? "12px" : "6px",
        gap: loading === true ? 0 : "15px",
      }}
    >
      {loading === true ? (
        <Lottie
          className="loading"
          animationData={require("../../assets/animations/loading_image.json")}
          width={90}
          height={120}
        />
      ) : null}
      <div
        className="belly-data-container"
        style={{
          position: "relative",
          bottom:
            isSelected &&
            (selectorStyles.borderWidthStyle === "2px" ||
              selectorStyles.borderWidthStyle === "3px")
              ? "1px"
              : 0,
        }}
      >
        <img
          className={
            type === "torso"
              ? torsoClass
              : type === "cuisses"
              ? cuissesClass
              : loading
              ? "belly_choice__image_loading"
              : "belly_choice__image"
          }
          alt={type}
          src={image}
          onLoad={(v) => {
            setLoading(false);
          }}
        />
        <span
          style={{
            ...selectorStyles,
            border: "none",
            fontFamily: `${font}, sans-serif`,
            textTransform:
              selectorStyles?.textTransform === "capitalize"
                ? "none"
                : selectorStyles?.textTransform,
            backgroundColor: "transparent",
            top:
              !isMobile && isPetroneTorso
                ? "25px"
                : isMobile && isPetroneTorso
                ? 0
                : isMobile
                ? "5px"
                : "35px",
          }}
        >
          {selectorStyles?.textTransform === "capitalize"
            ? capitalizeFirstLetter(text)
            : text}
        </span>
      </div>
    </button>
  );
};

export default BellyChoice;
