import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import { brandsArray } from "../../../configs/configLoader";
import { font, policityStyles, welcomeScreenUI } from "../../../configs";
import { isPhysicalAndroidDevice } from "../../../utils";
import { MODAL_STEPS } from "../../../constants/modal";

import "./index.css";

interface IPropsMeasuredBy {
  step: any;
}

const MeasuredBy: FC<IPropsMeasuredBy> = ({ step }) => {
  const { t } = useTranslation("components/intro");

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const isMobileSRP = isMobile && brandDefined?.name === "SRP";
  const isAndroid = isPhysicalAndroidDevice();

  const customLinkStyles = {
    fontSize: policityStyles.fontSize,
    color: policityStyles.fontColor,
    fontWeight: policityStyles.fontWeight,
    textTransform: policityStyles.textTransform,
  };

  return (
    <div>
      {(!isMobile
        ? step?.number === MODAL_STEPS.INTRO.number
        : step?.number === MODAL_STEPS.GENDER.number) && (
        <div
          className="policy"
          style={{
            position:
              isMobileSRP || (!isMobile && !isMobileSRP) ? "fixed" : "relative",
            bottom: isMobileSRP || (!isMobile && !isMobileSRP) ? "30px" : "0",
            top:
              isMobileSRP || (!isMobile && !isMobileSRP) ? "inherit" : "10px",
            color: customLinkStyles.color,
            fontSize: customLinkStyles.fontSize,
          }}
          dangerouslySetInnerHTML={{
            __html: t("pp_link").replace(/<a(.*?)>/g, (match: any) => {
              return `<a style="${Object.entries(customLinkStyles)
                .map(([key, value]) => `${key}: ${value}`)
                .join("; ")}" target="_blank"${match.slice(2)}`;
            }),
          }}
        />
      )}
      <div
        className="measured-by"
        style={{
          position:
            isMobileSRP || (!isMobile && !isMobileSRP) ? "fixed" : "relative",
          bottom: isMobileSRP || (!isMobile && !isMobileSRP) ? "10px" : "0",
          top: isMobileSRP || (!isMobile && !isMobileSRP) ? "inherit" : "10px",
          color: welcomeScreenUI.genderCTA.unfocused.fontColor,
          fontSize: welcomeScreenUI.genderCTA.unfocused.fontSize,
        }}
      >
        Measured by{" "}
        <span
          style={{
            color: welcomeScreenUI.genderCTA.unfocused.fontColor,
            fontSize: welcomeScreenUI.genderCTA.unfocused.fontSize,
            position: "relative",
            top: isAndroid && font === "Futura" ? "3px" : 0,
          }}
        >
          kleep
        </span>{" "}
      </div>
    </div>
  );
};

export default MeasuredBy;
