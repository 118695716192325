export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const SUCCESS_OR_ERROR_RESULT = true;

export const MODAL_STEPS = {
  CONSENT: { number: 0 },
  GENDER: { number: 1 },
  INTRO_MOBILE: { number: 2 },
  INTRO: { number: 3 },
  DEVICE_SELECT: { number: 4 },
  QR_CODE: { number: 5 },
  BELLY: { number: 6 },
  TORSO: { number: 7 },
  CUISSES: { number: 8 },
  BREASTS: { number: 9 },
  ERROR: { number: 10 },
  RESULT: { number: 11 },
  UNAVAILABLE_RESULT: { number: 12 },
  ANTI_BRACKETING: { number: 13 },
};

export const GENDERS = {
  F: "female",
  M: "male",
};

export const BASE_SIZES = [
  {
    name: "UK",
    image: "UK",
    label: "UK",
    band_sizes: [
      {
        size: 28,
        fr: 75,
      },
      {
        size: 30,
        fr: 80,
      },
      {
        size: 32,
        fr: 85,
      },
      {
        size: 34,
        fr: 90,
      },
      {
        size: 36,
        fr: 95,
      },
      {
        size: 38,
        fr: 100,
      },
      {
        size: 40,
        fr: 105,
      },
      {
        size: 42,
        fr: 110,
      },
      {
        size: 44,
        fr: 115,
      },
      {
        size: 46,
        fr: 120,
      },
      {
        size: 48,
        fr: 125,
      },
    ],
    cup_sizes: [
      {
        size: "A",
        fr: "A",
      },
      {
        size: "B",
        fr: "B",
      },
      {
        size: "C",
        fr: "C",
      },
      {
        size: "D",
        fr: "D",
      },
      {
        size: "DD",
        fr: "E",
      },
      {
        size: "E",
        fr: "F",
      },
      {
        size: "F",
        fr: "G",
      },
    ],
  },
  {
    name: "EU",
    image: "EU",
    label: "Europe",
    band_sizes: [
      {
        size: 60,
        fr: 75,
      },
      {
        size: 65,
        fr: 80,
      },
      {
        size: 70,
        fr: 85,
      },
      {
        size: 75,
        fr: 90,
      },
      {
        size: 80,
        fr: 95,
      },
      {
        size: 85,
        fr: 100,
      },
      {
        size: 90,
        fr: 105,
      },
      {
        size: 95,
        fr: 110,
      },
      {
        size: 100,
        fr: 115,
      },
      {
        size: 105,
        fr: 120,
      },
    ],
    cup_sizes: [
      {
        size: "AA",
        fr: "AA",
      },
      {
        size: "A",
        fr: "A",
      },
      {
        size: "B",
        fr: "B",
      },
      {
        size: "C",
        fr: "C",
      },
      {
        size: "D",
        fr: "D",
      },
      {
        size: "E",
        fr: "E",
      },
      {
        size: "F",
        fr: "F",
      },
      {
        size: "G",
        fr: "G",
      },
    ],
  },
  {
    name: "US",
    image: "US",
    label: "USA",
    band_sizes: [
      {
        size: 28,
        fr: 75,
      },
      {
        size: 30,
        fr: 80,
      },
      {
        size: 32,
        fr: 85,
      },
      {
        size: 34,
        fr: 90,
      },
      {
        size: 36,
        fr: 95,
      },
      {
        size: 38,
        fr: 100,
      },
      {
        size: 40,
        fr: 105,
      },
      {
        size: 42,
        fr: 110,
      },
      {
        size: 44,
        fr: 115,
      },
      {
        size: 46,
        fr: 120,
      },
      {
        size: 48,
        fr: 125,
      },
    ],
    cup_sizes: [
      {
        size: "AA",
        fr: "AA",
      },
      {
        size: "A",
        fr: "A",
      },
      {
        size: "B",
        fr: "B",
      },
      {
        size: "C",
        fr: "C",
      },
      {
        size: "D",
        fr: "D",
      },
      {
        size: "DD",
        fr: "E",
      },
      {
        size: "DDD/F",
        fr: "F",
      },
      {
        size: "DDDD/G",
        fr: "G",
      },
    ],
  },
  {
    name: "AUS",
    image: "AUS",
    label: "Australia",
    band_sizes: [
      {
        size: 6,
        fr: 75,
      },
      {
        size: 8,
        fr: 80,
      },
      {
        size: 10,
        fr: 85,
      },
      {
        size: 12,
        fr: 90,
      },
      {
        size: 14,
        fr: 95,
      },
      {
        size: 16,
        fr: 100,
      },
      {
        size: 18,
        fr: 105,
      },
      {
        size: 20,
        fr: 110,
      },
    ],
    cup_sizes: [
      {
        size: "A",
        fr: "A",
      },
      {
        size: "B",
        fr: "B",
      },
      {
        size: "C",
        fr: "C",
      },
      {
        size: "D",
        fr: "D",
      },
      {
        size: "DD",
        fr: "E",
      },
      {
        size: "E",
        fr: "F",
      },
      {
        size: "F",
        fr: "G",
      },
    ],
  },
  {
    name: "IT",
    image: "IT",
    label: "Italy",
    band_sizes: [
      {
        size: 0,
        fr: 80,
      },
      {
        size: 1,
        fr: 85,
      },
      {
        size: 2,
        fr: 90,
      },
      {
        size: 3,
        fr: 95,
      },
      {
        size: 4,
        fr: 100,
      },
      {
        size: 5,
        fr: 105,
      },
      {
        size: 6,
        fr: 110,
      },
    ],
    cup_sizes: [
      {
        size: "A",
        fr: "A",
      },
      {
        size: "B",
        fr: "B",
      },
      {
        size: "C",
        fr: "C",
      },
      {
        size: "D",
        fr: "D",
      },
      {
        size: "E",
        fr: "E",
      },
      {
        size: "F",
        fr: "F",
      },
      {
        size: "G",
        fr: "G",
      },
    ],
  },
  {
    name: "JP",
    image: "JP",
    label: "Japan",
    band_sizes: [
      {
        size: 60,
        fr: 75,
      },
      {
        size: 65,
        fr: 80,
      },
      {
        size: 70,
        fr: 85,
      },
      {
        size: 75,
        fr: 90,
      },
      {
        size: 80,
        fr: 95,
      },
      {
        size: 85,
        fr: 100,
      },
      {
        size: 90,
        fr: 105,
      },
      {
        size: 95,
        fr: 110,
      },
      {
        size: 100,
        fr: 115,
      },
      {
        size: 105,
        fr: 120,
      },
    ],
    cup_sizes: [
      {
        size: "A",
        fr: "AA",
      },
      {
        size: "B",
        fr: "A",
      },
      {
        size: "C",
        fr: "B",
      },
      {
        size: "D",
        fr: "C",
      },
      {
        size: "E",
        fr: "D",
      },
      {
        size: "F",
        fr: "E",
      },
      {
        size: "G",
        fr: "F",
      },
      {
        size: "H",
        fr: "G",
      },
    ],
  },
  {
    name: "KO",
    image: "KO",
    label: "Korea",
    band_sizes: [
      {
        size: 60,
        fr: 75,
      },
      {
        size: 65,
        fr: 80,
      },
      {
        size: 70,
        fr: 85,
      },
      {
        size: 75,
        fr: 90,
      },
      {
        size: 80,
        fr: 95,
      },
      {
        size: 85,
        fr: 100,
      },
      {
        size: 90,
        fr: 105,
      },
      {
        size: 95,
        fr: 110,
      },
      {
        size: 100,
        fr: 115,
      },
      {
        size: 105,
        fr: 120,
      },
    ],
    cup_sizes: [
      {
        size: "A",
        fr: "AA",
      },
      {
        size: "B",
        fr: "A",
      },
      {
        size: "C",
        fr: "B",
      },
      {
        size: "D",
        fr: "C",
      },
      {
        size: "E",
        fr: "D",
      },
      {
        size: "F",
        fr: "E",
      },
      {
        size: "G",
        fr: "F",
      },
      {
        size: "H",
        fr: "G",
      },
    ],
  },
  {
    name: "FR",
    image: "FR",
    label: "France",
    band_sizes: [
      {
        size: 75,
      },
      {
        size: 80,
      },
      {
        size: 85,
      },
      {
        size: 90,
      },
      {
        size: 95,
      },
      {
        size: 100,
      },
      {
        size: 105,
      },
      {
        size: 110,
      },
      {
        size: 115,
      },
      {
        size: 120,
      },
      {
        size: 125,
      },
    ],
    cup_sizes: [
      {
        size: "AA",
      },
      {
        size: "A",
      },
      {
        size: "B",
      },
      {
        size: "C",
      },
      {
        size: "D",
      },
      {
        size: "E",
      },
      {
        size: "F",
      },
      {
        size: "G",
      },
    ],
  },
];

export const ERROR_EMPTY = {
  height: {
    error: false,
    message: "",
  },
  weight: {
    error: false,
    message: "",
  },
  age: {
    error: false,
    message: "",
  },
};

export const VALIDATION_RULES = {
  height: {
    min: 120,
    max: 230,
    feet: {
      min: 3,
      max: 11,
    },
    inches: {
      min: 7,
      max: 6,
    },
  },
  weight: {
    kg: {
      min: 20,
      max: 200,
    },
    lbs: {
      min: 44,
      max: 439,
    },
  },
  age: {
    min: 1,
    max: 100,
  },
};
