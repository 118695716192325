import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { brandsArray } from "./configs/configLoader";

const cleanDomain = (domain: string) => {
  const patternsToRemove = [".com", ".fr", "www."];
  return patternsToRemove.reduce(
    (result, pattern) => result.replace(pattern, ""),
    domain
  );
};

const urlParams = new URLSearchParams(window.location.search);
const requestDomain = urlParams.get("domain");

const getMainDomainFromRequest = (domain: string) => {
  for (const brand of brandsArray) {
    if (brand.domains.includes(domain)) {
      return cleanDomain(brand.domains[0]);
    }
  }
  return "default";
};

const brand = requestDomain
  ? getMainDomainFromRequest(requestDomain)
  : "default";

const namespaces = [
  "components/intro",
  "components/device_select",
  "components/qrcode/desktop",
  "components/qrcode/mobile",
  "components/belly",
  "components/torso",
  "components/cuisses",
  "components/breasts",
  "components/results/error",
  "components/results/result",
  "components/antiBracketing",
  "components/consentement",
];

export async function loadTranslations(lang: string) {
  const lng = lang || "fr";
  const translationFilePath = `/locales/translations.json`;

  try {
    const response = await fetch(translationFilePath);
    if (!response.ok) {
      console.error(`Failed to load translations from ${translationFilePath}`);
      return;
    }

    const allTranslations = await response.json();
    const defaultTranslations = allTranslations["default"] || {};

    let brandTranslations: Record<string, any> = allTranslations[brand] || {};

    if (
      typeof brandTranslations === "object" &&
      Object.keys(brandTranslations).length === 0 &&
      brand !== "default"
    ) {
      const groupedKey = Object.keys(allTranslations).find((key) =>
        key.split("/").includes(brand)
      );
      brandTranslations = groupedKey ? allTranslations[groupedKey] : {};
    }

    for (const ns of namespaces) {
      const defaultNS = defaultTranslations[lng]?.[ns] || {};
      const brandNS =
        brandTranslations[lng]?.[ns] ||
        brandTranslations["default"]?.[ns] ||
        {};

      const mergedTranslation = { ...defaultNS, ...brandNS };

      i18n.addResourceBundle(lng, ns, mergedTranslation, true, true);
    }
  } catch (error) {
    console.error("Error loading translations:", error);
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    ns: namespaces,
    defaultNS: "components/intro",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: false,
  });

export { i18n };
