import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import MeasuredBy from "../molecules/MeasuredBy";

import {
  activeStylesContinue,
  welcomeScreenUI,
  titleStyles as titleStylesBase,
  subtitlesStyles,
} from "../../configs";
import { capitalizeFirstLetter } from "../../utils";

import "./index.css";

interface IPropsResultError {
  step: any;
  nextStep: () => void;
  restart: () => void;
  skip: () => void;
}

const ResultError: FC<IPropsResultError> = ({
  step,
  nextStep,
  restart,
  skip,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { t } = useTranslation("components/results/error");

  const titleTextTransform = welcomeScreenUI.input_fields.title
    .textTransform as React.CSSProperties["textTransform"];

  const titlesStyles = {
    fontWeight: "400",
    textAlign: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleTextTransform === "capitalize" ? "none" : titleTextTransform,
    color: titleStylesBase.color as React.CSSProperties["color"],
    fontSize: "14px",
    justifyContent: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["justifyContent"],
  };

  return (
    <div className="result-error">
      <span
        className="description"
        style={{
          ...titlesStyles,
          marginTop: isMobile ? 0 : "25px",
          textTransform: (subtitlesStyles.textTransform === "capitalize"
            ? "none"
            : subtitlesStyles.textTransform) as React.CSSProperties["textTransform"],
        }}
      >
        {subtitlesStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("error.description"))
          : t("error.description")}
      </span>

      <button
        type="button"
        className="continue-button"
        onClick={() => nextStep()}
        style={{
          ...activeStylesContinue,
          textTransform:
            activeStylesContinue.textTransform === "capitalize"
              ? "none"
              : activeStylesContinue.textTransform,
        }}
      >
        {activeStylesContinue.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("skip"))
          : t("skip")}
      </button>

      <MeasuredBy step={step} />
    </div>
  );
};

export default ResultError;
